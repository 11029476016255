import axios from "axios";

const URI_USUARIO = "/usuarios/api/v1/usuarios";
const URL_USUARIO_V2 = "/usuarios/api/v2/usuarios";
const URL_AUTH = "/usuarios/api/v1/auth";

export const PERMISSOES = [
  {
    name: "Acesso App Views",
    id: "acessa_aplicativo",
    description: "Permite o login ao FarmtellViews",
  },
  {
    name: "Acesso Portal Views Manejo",
    id: "acessa_plataforma",
    description: "Permite o login ao FarmtellViews",
  },
  {
    name: "Estoque Final",
    id: "estoque_final",
    description: "Permite acesso a coleta de estoque final",
  },
  {
    name: "Importar mapas",
    id: "importar_mapas",
    description: "Permite importar arquivos kml do mapa da fazenda",
  },
  {
    name: "Exportar/Importar CSV pasto",
    id: "exportar_importar_csv_pasto",
    description: "Permite a importar/exportar CSV para edição de pastos",
  },
  {
    name: "Cadastrar ciclo de forragems",
    id: "cadastrar_ciclo_forragem",
    description: "Permite o cadastro de ciclo de forragem",
  },
];

class UsuarioService {
  async obtemUsuarios() {
    return axios.get(`${URI_USUARIO}`, {
      headers: {},
    });
  }

  async obtemUsuarioPorFiltro(filtro) {
    return axios.get(`${URI_USUARIO}?filter=${JSON.stringify(filtro)}`);
  }

  async obtemUsuarioPorEmail(email) {
    return await axios.get(
      `${URI_USUARIO}?filter={"where":{"Email":"${email}","$and": {"Excluido":false}}}`
    );
  }

  async obtemUsuariosAdministradoresPorCliente(IdCliente) {
    return axios.get(`${URI_USUARIO}/clientes/${IdCliente}?UsuarioAdministrador=1&Excluido=0`);
  }

  async cadastraUsuario(usuarioCadastrar) {
    return axios.post(`${URI_USUARIO}`, usuarioCadastrar);
  }

  async cadastraUsuarioAdministrador(IdUsuario, IdCliente) {
    return axios.post(`${URI_USUARIO}/${IdUsuario}/clientes/administrador`, { IdCliente });
  }

  async salvaUsuario(IdUsuario, usuarioSalvar) {
    return axios.put(`${URI_USUARIO}/${IdUsuario}`, usuarioSalvar);
  }

  async excluiUsuario(IdUsuario) {
    return axios.delete(`${URI_USUARIO}/${IdUsuario}`);
  }

  async excluiUsuarioAdministrador(IdCliente, IdUsuario) {
    return axios.delete(`${URI_USUARIO}/${IdUsuario}/clientes/${IdCliente}/administrador`);
  }

  async obtemFazendasUsuario(IdUsuario) {
    return axios.get(`${URI_USUARIO}/${IdUsuario}/fazendas`);
  }

  async obtemRetirosUsuario(IdUsuario) {
    return axios.get(`${URI_USUARIO}/${IdUsuario}/retiros`);
  }

  async salvaVinculosUsuarioRetiro(IdUsuario, vinculosUsuarioRetiro) {
    return axios.post(`${URL_USUARIO_V2}/${IdUsuario}/retiros/bulk`, vinculosUsuarioRetiro);
  }

  async salvaVinculosUsuarioFazenda(IdUsuario, vinculosUsuarioFazenda) {
    return axios.post(`${URL_USUARIO_V2}/${IdUsuario}/fazendas/bulk`, vinculosUsuarioFazenda);
  }

  async salvaVinculosUsuarioRetiroPeloIdFazenda(IdUsuario, IdFazenda) {
    return axios.post(`${URI_USUARIO}/${IdUsuario}/fazendas/${IdFazenda}/retiros/bulk`, []);
  }

  async obtemUsuariosV2(limit, offset, filter) {
    const filterParsed = Object.entries(filter)
      .filter(
        ([_, value]) =>
          !(
            value === null ||
            value === undefined ||
            value === "" ||
            (Array.isArray(value) && value.length === 0)
          )
      )
      .reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: Array.isArray(value) ? JSON.stringify(value) : value,
        }),
        {}
      );
    try {
      const query = {
        limit,
        offset,
        ...filterParsed,
      };

      const response = await axios.get(`${URL_USUARIO_V2}`, {
        params: query,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async buscaUsuario(login) {
    return axios.get(`${URL_USUARIO_V2}/${login}`).then((response) => response.data);
  }

  async atualizaPermissoesUsuario(login, permissoes) {
    return axios.put(`${URI_USUARIO}/${login}/permissoes`, permissoes);
  }

  async alterarSenha(passwordData) {
    try {
      const response = await axios.post(
        `${URL_AUTH}/reset-password`,
        passwordData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  async verificarExistenciaUsuario(username, email) {
    const query = {
      username,
      email,
    };
    try {
      const response = await axios.get(
        `${URL_USUARIO_V2}/verificaExistencia?${new URLSearchParams(query)}`
      );

      return response && response.data;
    } catch (error) {
      throw error;
    }
  }
}
export default new UsuarioService();
