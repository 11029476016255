import { withStyles } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import * as actions from "../../../shared/components/Loading/redux/loadingAction";
import history from "../../app/history";
import { notificaErro } from "../../notificacao/redux/notificacaoActions";
import auth from "../Auth";
import styles from "./styles";

export const LoginPage = ({ classes, exibirCarregando, notifyError }) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [error, setError] = useState(false);

  const handleShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      setError(false);
      exibirCarregando(true);

      const username = event.target.username && event.target.username.value;
      const password = event.target.password && event.target.password.value;

      if (!username || !password) {
        exibirCarregando(false);
        notifyError("Informe o usuário e a senha");
        setError(true);
        return;
      }

      await auth.login(username, password);
    } catch (error) {
      notifyError(error.message);
      setError(true);
    } finally {
      exibirCarregando(false);
    }
  };

  const navigateToForgotPasswordPage = () => {
    history.push("/recupera_senha");
  };

  return (
    <div className={classes.container}>
      <div className={classes.loaderWrapper}>
        <div className={classes.loader}></div>
      </div>
      <div className={classes.loginPage}>
        <div className={classes.loginForm}>
          <header>
            <div className={classes.logoWrapper}>
              <img
                className={classes.logoWrapperImg}
                src="https://storage.googleapis.com/armazenamento-imagens/logo/logo-Farmtell-Views-Cor.svg"
                alt=""
              />
            </div>

            <div className={classes.greetings}>
              <h1 className={classes.greetingWelcome}>Gestão de acessos</h1>
            </div>
          </header>

          <form className={classes.form} onSubmit={onSubmit}>
            <div
              className={[
                classes.inputWrapper,
                error ? classes.onError : "",
              ].join(" ")}
            >
              <label className={classes.formControlLabel} htmlFor="username">
                Usuário
              </label>
              <input
                className={classes.loginInput}
                type="text"
                name="username"
                placeholder="Informe o nome do usuário para acessar"
              />
              <span className={classes.spanIcon}>
                <img
                  width="20px"
                  height="18px"
                  src="https://storage.googleapis.com/armazenamento-imagens/assets/username-new-blue.svg"
                  alt=""
                />
              </span>
            </div>
            <div
              className={[
                classes.inputWrapper,
                error ? classes.onError : "",
              ].join(" ")}
            >
              <label className={classes.formControlLabel} htmlFor="password">
                Senha
              </label>
              <input
                className={classes.loginPasswordInput}
                type={passwordVisibility ? "text" : "password"}
                name="password"
                placeholder="Informe sua senha"
              />
              <span
                className={classes.spanIcon}
                data-testid={"show-password-icon"}
                onClick={handleShowPassword}
              >
                {passwordVisibility ? (
                  <img
                    data-testid={"visibilityOn"}
                    width="20px"
                    height="21px"
                    src="https://storage.googleapis.com/armazenamento-imagens/assets/visibility-on-new-blue.svg"
                    alt="visibilityOn"
                  />
                ) : (
                  <img
                    data-testid={"visibilityOff"}
                    width="20px"
                    height="21px"
                    src="https://storage.googleapis.com/armazenamento-imagens/assets/visibility-off-new-blue.svg"
                    alt="visibilityOff"
                  />
                )}
              </span>
            </div>

            <div className={classes.forgotPasswordWrapper}>
              <span
                className={classes.forgotPasswordLink}
                onClick={navigateToForgotPasswordPage}
              >
                Esqueci a senha
              </span>
            </div>

            <button className={classes.loginButton} type="submit">
              Entrar
            </button>
          </form>
        </div>

        <div className={classes.bgOxWrapper}></div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  exibirCarregando: actions.exibirCarregando,
  notifyError: notificaErro,
};

export default compose(
  connect(null, mapDispatchToProps),
  withStyles(styles)
)(LoginPage);
