import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Page } from '../../../shared/components';
import DialogConfirmacaoAcao from '../../../shared/components/dialogConfirmacaoAcao';
import * as actions from "../../notificacao/redux/notificacaoActions";
import { useFiltroUsuario } from "../hooks/useFiltroUsuario";
import * as usuarioOperations from '../redux/usuarioOperations';
import usuarioService from '../usuarioService';
import UsuarioTable from './UsuarioTable';
import FiltroUsuario from './components/FiltroUsuario';
import { TEXTO_DIALOG_EXLUSAO, TITULO_DIALOG_EXLUSAO } from './textosUsuario.ig';
import UsuarioForm from './usuarioForm';

function UsuarioPage(props) {
  const [state, setState] = useState({
    editando: false,
    formularioAberto: false,
    confirmacaoExclusaoAberto: false,
    usuarioSelecionado: null,
    idUsuario: null,
    nome: '',
    email: '',
    login: '',
    loginRepetido: false,
    emailRepetido: false,
    usuarioTableau: false,
    usuarioInterno: false
  });
  const [atualizaListaUsuarios, setAtualizaListaUsuarios] = useState(Math.random()); // usado para forcar a atualizacao da lista de usuarios

  const [loading, setLoading] = useState(false);

  const { filtro, alterarValorFiltro } = useFiltroUsuario();

  const setCamposFormUsuario = (value, campo) => {
    setState((prevState) => ({
      ...prevState,
      [campo]: value
    }));
  };

  const verificaExistenciaUsuario = async ({ login, email }) => {
    if (state.usuarioSelecionado && state.usuarioSelecionado.email === email) {
      return;
    }
    const resposta = await usuarioService.verificarExistenciaUsuario(login, email);
    if (resposta.username || resposta.email) {
      setState((prevState) => ({
        ...prevState,
        loginRepetido: resposta.username,
        emailRepetido: resposta.email
      }));
      throw new Error("Usuário já cadastrado.")
    }
  };

  const abreFechaFormNovoUsuario = () => {
    setState(prevState => {
      return {
        formularioAberto: !prevState.formularioAberto,
        editando: false,
        idUsuario: null,
        confirmacaoExclusaoAberto: false,
        usuarioSelecionado: null,
        nome: '',
        email: '',
        login: '',
        loginRepetido: false,
        emailRepetido: false,
        usuarioTableau: false,
        usuarioInterno: false
      };
    });
  };

  const salvaUsuario = async () => {
    const { idUsuario, nome, login, email, senha, usuarioInterno, usuarioTableau, usuarioSelecionado } = state;
    try {
      await props.salvaUsuario(
        idUsuario,
        {
          Nome: nome,
          Email: email,
          Login: login,
          Senha: senha,
          UsuarioTableau: usuarioTableau,
          UsuarioInterno: usuarioInterno,
          usuarioSelecionado
        },
        verificaExistenciaUsuario
      );
      abreFechaFormNovoUsuario();
      setAtualizaListaUsuarios(Math.random());
    } catch (err) {
      console.error(err);
    }
  };

  const abreFechaDialogoExclusao = (usuario = null) => {
    setState(prevState => {
      return {
        ...prevState,
        confirmacaoExclusaoAberto: !prevState.confirmacaoExclusaoAberto,
        usuarioSelecionado: usuario
      };
    });
  }

  const excluiUsuario = async () => {
    const { usuarioSelecionado } = state;
    if (usuarioSelecionado) {
      abreFechaDialogoExclusao();
      await props.excluiUsuario(usuarioSelecionado.idUsuario, usuarioSelecionado.email);
      alterarValorFiltro("busca")("")
    }
  };

  const editaUsuario = usuario => {
    console.log({ usuario })
    window.scrollTo(0, 0);
    setState({
      formularioAberto: true,
      editando: true,
      idUsuario: usuario.idUsuario,
      nome: usuario.nome,
      email: usuario.email,
      login: usuario.login,
      usuarioTableau: usuario.usuarioTableau,
      usuarioInterno: usuario.usuarioInterno,
      usuarioSelecionado: usuario
    });
  }

  const enviarLinkSenha = async (usuario) => {
    try {
      await usuarioService.alterarSenha({ email: usuario.email });
      props.notifySuccess("Link enviado com sucesso.");
    } catch (err) {
      console.error(err);
      props.notifyError("Ocorreu um erro ao enviar o link para alterar a senha.");
    }
  };

  const gerarLinkSenha = async (usuario) => {
    try {
      const response = await usuarioService.alterarSenha({
        email: usuario.email,
        idUsuario: usuario.idUsuario,
      });
      navigator.clipboard.writeText(response.url);
      props.notifySuccess("Link copiado para área de transferência.");
    } catch (err) {
      console.error(err);
      props.notifyError("Ocorreu um erro ao enviar o link para alterar a senha.");
    }
  };

  const vincularUsuario = (usuario) => {
    props.history.push({
      pathname: `usuarios/${usuario.idUsuario}/vinculos`,
      usuario: {
        IdUsuario: usuario.idUsuario,
        Nome: usuario.nome,
        Email: usuario.email,
        Login: usuario.login,
        UsuarioTableau: usuario.usuarioTableau,
        UsuarioInterno: usuario.usuarioInterno,
      }
    });
  };

  const prepararFiltro = ({ value, options }) => {
    if (value.length === options.length) {
      return null;
    }
    return value.map(item => item.value);
  }

  const listarUsuarios = useCallback(
    async ({ limit, offset }) => {
      try {
        setLoading(true);
        const { clientes, fazendas, permissoes } = filtro

        const idCliente = prepararFiltro(clientes)
        const idFazenda = prepararFiltro(fazendas)
        const permissao = prepararFiltro(permissoes)

        const users = await usuarioService.obtemUsuariosV2(
          limit,
          offset,
          {
            busca: filtro.busca.value,
            idCliente,
            idFazenda,
            permissao
          }
        );
        return {
          total: users.total,
          itens: users.data,
        };
      } catch (err) {
        console.error(err);
        props.notifyError({
          mensagem: "Ocorreu um erro ao buscar as usuários.",
          variant: "error",
        });
        return {
          total: 0,
          itens: [],
        };
      } finally {
        setLoading(false);
      }
    },
    [atualizaListaUsuarios, filtro.busca.value, filtro.clientes.value, filtro.fazendas.value, filtro.permissoes.value]
  );

  const {
    nome,
    login,
    email,
    loginRepetido,
    emailRepetido,
    usuarioTableau,
    usuarioInterno,
    confirmacaoExclusaoAberto,
    usuarioSelecionado,
    editando,
    formularioAberto
  } = state;

  return (
    <React.Fragment>
      <Page
        titulo="Usuários"
        conteudo={
          <React.Fragment>
            <FiltroUsuario
              filtro={filtro}
              carregando={loading}
              alterarFiltro={alterarValorFiltro}
              abreFechaFormNovoCadastro={abreFechaFormNovoUsuario}
            />
            <UsuarioForm
              formularioAberto={formularioAberto}
              nome={nome}
              login={login}
              email={email}
              usuarioTableau={usuarioTableau}
              usuarioInterno={usuarioInterno}
              setCamposFormUsuario={setCamposFormUsuario}
              editando={editando}
              abreFechaFormNovoUsuario={abreFechaFormNovoUsuario}
              salvaUsuario={salvaUsuario}
              loginRepetido={loginRepetido}
              emailRepetido={emailRepetido}
            />
            <UsuarioTable
              listarUsuarios={listarUsuarios}
              abreDialogoExclusao={abreFechaDialogoExclusao}
              editaUsuario={editaUsuario}
              gerarLinkSenha={gerarLinkSenha}
              enviarLinkSenha={enviarLinkSenha}
              vincularUsuario={vincularUsuario}
            />

          </React.Fragment>
        }
        conteudoGridProps={{ spacing: 0 }}
      />
      {confirmacaoExclusaoAberto && usuarioSelecionado ? <DialogConfirmacaoAcao
        id={`dialogConfirmaExclusaoUsuario`}
        tituloDialog={TITULO_DIALOG_EXLUSAO}
        textoDialog={
          <span id="mensagemConfirmaExclusaoUsuario">
            {TEXTO_DIALOG_EXLUSAO.replace(/<nome>/g, usuarioSelecionado.Nome)}
          </span>
        }
        dialogAberto={confirmacaoExclusaoAberto}
        cancelarAcaoDialog={abreFechaDialogoExclusao}
        confirmarAcaoDialog={excluiUsuario}
      /> : null}
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  salvaUsuario: usuarioOperations.salvaUsuario,
  excluiUsuario: usuarioOperations.excluiUsuario,
  notifyError: actions.notificaErro,
  notifySuccess: actions.notificaSucesso,
};

export default compose(
  connect(null, mapDispatchToProps),
  withRouter
)(UsuarioPage);
